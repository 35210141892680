<template>
  <div class="view pa24">
    <div class="view mr mb20">
      <span style="color: #8d97ad; font-size: 14px" v-if="userInfo.roleId">设置部门和管理员流程:①请先在下方成员列表中选择相应员工,点击“编辑" , 设置为管理员------②在“部门设置"中,新增部门,选择相应管理员------③如需设置管理员相关权限,可在"管理员列表”里编辑修改管理员权限</span
      >
    </div>
    <el-row :gutter="24">
      <el-col :xl="24" :lg="16" class="d-flex justify-content-between w100p">
        <div class="d-flex align-items-center">
          <el-input
             v-model="searchKey"
             class="mb10"
             style="width: 240px"
             placeholder="请输入员工姓名"
          />
          <el-button class="ml10 mb10" type="primary" @click="getCorporator">搜索</el-button>
          <!-- <el-button class="ml10 mb10">导出</el-button> -->
        </div>
        <div class="d-flex align-items-center">
          <el-button type="primary"
                     class="mb10"
                     v-if="userInfo.userType == 2"
                     :disabled="!hasPermi"
                     @click="changeBoss"
                    >更换超管</el-button>
          <el-button type="primary"
                     class="mb10"
                     v-if="userInfo.roleId || jurisdictionA || userInfo.main"
                    @click="sectorSetup">部门设置</el-button>
          <el-button type="primary"
                     class="mb10"
                     v-if="userInfo.roleId || jurisdictionB || userInfo.main"
                     @click="AdministratorList">管理员列表</el-button>
        </div>
      </el-col>

    </el-row>
    <commonTable :tableData="tableData"
                 @handleSizeChange="handleSizeChange"
                 @handleCurrentChange="handleCurrentChange"
                 :currentPage="currentPage"
                 :loading="loading"
                 :total="total">
      <template v-slot:table>
        <!-- <el-table-column type="selection" width="55" /> -->
        <el-table-column prop="jobNumber" width="100px" align="center" label="工号" />
        <el-table-column prop="employeeName" align="center" label="姓名" />
        <el-table-column
          prop="position"
          align="center"
          label="职位"
          show-overflow-tooltip
        />
        <el-table-column prop="departmentName" align="center" label="部门" />
        <el-table-column prop="personalWx" align="center" label="微信号" />
        <el-table-column prop="phone" align="center" label="手机" />
        <el-table-column prop="character" width="100px" align="center" label="角色" />
        <el-table-column prop="createTime" align="center" label="创建时间" />
        <el-table-column prop="monopolismCus" align="center" label="独占客户数" />
        <el-table-column prop="dealCus" align="center" label="成单客户数" />
        <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="downCodeImg(scope.row)">下载名片码</el-button>
<!--              <el-button type="text" @click="bind_tap(scope.row)">关联用户</el-button>-->
              <el-button type="text" @click="edit_tap(scope.row)" :disabled="!hasPermi">编辑</el-button>
              <el-button type="text" @click="detall_tap(scope.row.jobNumber)">详情</el-button>
              <el-button type="text" class="tag-read" @click="copyShareLink(scope.row)" :data-clipboard-text="Link">分享链接</el-button>
            </template>
            <!-- <el-button type="text" style="color: #4484fd">编辑</el-button> -->
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
      title="编辑成员"
      center
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      width="25%"
      :close-on-click-modal="false"
    >
      <!-- <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="auto"
        class="ruleForm d-flex flex-column align-items-center"
      > -->
      <el-form
        label-width="auto"
        class="ruleForm d-flex flex-column align-items-center"
      >
        <el-form-item label="工号">
          <el-input
            readonly
            style="width: 240px"
            v-model="memberInfo.jobNumber"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input style="width: 240px" v-model="memberInfo.name"></el-input>
        </el-form-item>
        <el-form-item label="职位">
          <el-input style="width: 240px" v-model="memberInfo.position"></el-input>
        </el-form-item>
        <el-form-item label="微信号">
          <el-input
            style="width: 240px"
            v-model="memberInfo.personalWx"
          ></el-input>
        </el-form-item>
        <el-form-item label="名片展示手机号">
          <el-input
            type="number"
            maxlength="11"
            readonly
            style="width: 240px"
            v-model="memberInfo.phone"
          ></el-input>
        </el-form-item>
        <el-form-item label="部门">
          <el-select
            v-model="memberInfo.departmentId"
            style="width: 240px"
            :disabled="setDepartment"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in departmentList"
              :key="index"
              :label="item.name"
              :value="item.departmentId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设置成员角色" prop="memberRole" v-if="memberInfo.userType !== 2">
          <div style="width: 240px">
            <el-radio v-model="memberInfo.userType" :label="0" :disabled="setAdministrator"
              >普通成员</el-radio
            >
            <el-radio v-model="memberInfo.userType" :label="1" :disabled="setAdministrator">管理员</el-radio>
          </div>
        </el-form-item>
        <el-form-item label="设置成员角色" prop="memberRole" v-else>
          <div style="width: 240px">
            <el-radio v-model="memberInfo.userType" :label="0" :disabled="memberInfo.userType == 2"
              >普通成员</el-radio
            >
            <el-radio v-model="memberInfo.userType" :label="1" :disabled="memberInfo.userType == 2">管理员</el-radio>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="affirm_tap">确 定</el-button>
      </span>
    </el-dialog>

    <!--更换超管弹窗-->
    <el-dialog
      title="更换超管"
      center
      :visible.sync="changeDialogVisible"
      :modal-append-to-body="false"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form
        label-width="auto"
        class="ruleForm d-flex flex-column align-items-center"
      >
        <el-form-item label="新超级管理员">
          <el-select
            v-model="newUserId"
            style="width: 240px"
            placeholder="请选择"
            required
          >
            <el-option
              v-for="(item, index) in allTableData"
              :key="index"
              :label="item.name"
              :value="item.userId"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="closeChangeDialog">取 消</el-button>
        <el-button type="primary" @click="handleChangeBoss"
          >确 定</el-button
        >
      </span>

    </el-dialog>
    <!--扫码记录-->
    <BindUserDialog ref="bindUserDialog"></BindUserDialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { queryPageEmployee,queryEmployee,queryPageDepartment,updateEmployee, getWxCodeImg, fetchChangeBoss} from "@/api/companyManage.js";
import { getDataTimeSec } from "@/utils/index";
import { mapState } from 'vuex';
import { filterUserRoot } from '@/utils/utils.js'
import Clipboard from 'clipboard'
import utils from "@/utils/index";
import BindUserDialog from "./bindUserDialog.vue";

export default {
  name: "enterpriseMember",
  components: {
    BindUserDialog,
    commonTable,
  },
  data() {
    return {
      hasPermi: true,
      tableData: [],
      departmentOptions: [{ value: "1", lable: "普通成员" }],
      dialogVisible: false,
      total: 0, //总条数
      currentPage: 1, //当前页数
      queryDate: "",
      pageNum: 1,
      pageSize: 10,
      loading: false, //加载中
      searchKey:'', //关键字
      jurisdictionA:'',
      jurisdictionB:'',
      jurisdictionC:'',
      jurisdictionD:'',
      jurisdictionF:'',
      userInfo: this.$store.state.loginRoot.userInfo,
      departmentList:[],
      memberInfo:{
        phone:'',
        personalWx:'',
        jobNumber:'',
        position:'',
        departmentId:'',
        userType:'',
        queryType:'', //请求类型

      },
      setAdministrator:false, // 是否可设置管理员
      setDepartment:false,// 是否可设置部门,
      changeDialogVisible: false, // 更换超管
      allTableData: [],
      newUserId:'',
      Link: null, // 小程序分享链接
    };
  },
  computed: {
    ...mapState(['routerArr']),
  },
  watch:{
    // routerArr(val){
    //   this.jurisdictionA = val.some(item => ['0210','02100','02101'].includes(item))//部门管理
    //   this.jurisdictionB = val.includes('0211')//管理员设置
    //   this.jurisdictionC = val.includes('0212')//成员设置
    //   this.jurisdictionD = val.some(item => ['02100'].includes(item))//部门管理(自己)
    //   this.jurisdictionF = val.some(item => ['02101'].includes(item))//部门管理(所有)
    //   if(this.jurisdictionD){
    //     this.queryType = 'section'
    //     if(this.jurisdictionF){
    //       this.queryType = 'all'
    //     }
    //     this.getCorporator()
    //   }
    //   if(this.jurisdictionF){
    //     this.queryType = 'all'
    //     this.getCorporator()
    //   }
    // },
    jurisdictionB(val){
      if(this.userInfo.roleId){
        this.setAdministrator = false
      }else{
        this.setAdministrator = val ? false : true
      }

    },
    jurisdictionF(val){
      if(this.userInfo.roleId){
        this.setDepartment = false
      }else{
        this.setDepartment = val ? false : true
      }

    }
  },
  filters: {
    dateFilter(val) {
      console.log(val);
    },
  },
  mounted() {
    this.getCorporator();
    this.getAllCorporator()
  },
  created() {
    if(JSON.parse(localStorage.getItem('isSourceAdmin'))) {
      this.hasPermi = false
    }
  },
  methods: {
    // 复制名片小程序页面分享链接
    copyShareLink(data) {
      this.Link = `pages/index/main?cardId=${data.cardId}&goType=1&companyId=${data.companyId}`
      var clipboard = new Clipboard('.tag-read')
      clipboard.on('success', e => {
        console.log('复制成功')
        //  释放内存

        // clipboard.destory()
      })
      clipboard.on('error', e => {
        // 不支持复制
        console.log('该浏览器不支持复制')
        // 释放内存
        // clipboard.listener.destory()
      })
    },
    setInitRoutes(roots, userType) {
      let arr = []
      if(userType ==1 || userType == 2) {
        roots.forEach(item => {
          if(item.id) {
            arr.push(item.id)
            if(item.children) {
                item.children.forEach((item2) => {
                  arr.push(item2.id)
                  if(item2.children) {
                    item2.children.forEach((item3) => {
                      arr.push(item3.id)
                    })
                  }
                })

            }
          }
        })
        return arr
      }else { // 普通成员
        arr = ['00', '01','010', '02', '020']
      }
      return arr
    },
    getAllCorporator() {
      let data = {
        pageNum:1,
        pageSize:1000,
        name:this.searchKey,
        queryType:'all',
      }
      queryPageEmployee(data).then((res) => {
        if(res.data.pageInfo.list) {
          this.allTableData = res.data.pageInfo.list.filter(item => item.userId!== this.userInfo.userId);
        }
      })
    },
    //获取企业成员
    getCorporator(Type) {
      let userType = this.$store.state.loginRoot.userInfo.userType
      let queryType = ''
      if(userType == '0') {
        queryType = 'single'
      }else {
        queryType = 'all'
      }
      let data = {
        pageNum:this.pageNum,
        pageSize:this.pageSize,
        name:this.searchKey,
        // queryType:this.$store.state.loginRoot.userInfo.userType ? 'all' : this.queryType ? this.queryType : 'single'
        queryType: queryType
      }
      queryPageEmployee(data)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.pageInfo.list;
          this.total = res.data.pageInfo.total
          this.tableData.forEach((v) => {
            v.createTime = getDataTimeSec(v.createTime);
            if(v.userType == 0 ){
              v.character = '员工'
            }else if(v.userType == 1){
              v.character = '管理员'
            }else{
              v.character = '超级管理员'
            }
          });
        })
        .catch((err) => {
          this.tableData = []
          this.loading = false;
          console.log(err);
        });
    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.loading = true;
      this.getCorporator();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.loading = true;
      this.getCorporator();
    },
    //请求员工详情
    async queryEmployee(id){
      let data = {
        empId: id
      }
      let result = await queryEmployee(data)
      if(result.data.routers){
        result.data.routers = JSON.parse(result.data.routers || [])
      }
      this.memberInfo = result.data
      console.log(this.memberInfo,'info')
    },
    //请求部门信息
    getPageDepartment() {
      let data = {
        pageSize: 999,
      };
      queryPageDepartment(data)
      .then((res) => {
        this.departmentList = res.data.pageInfo.list || "";
      })
      .catch((err) => {
        this.departmentList = [];
      });
    },

    detall_tap(id){
      this.$router.push('/ent/memberDetail?id=' + id)
    },
    bind_tap(item){
      this.$refs.bindUserDialog.openDialog(item)
    },
    edit_tap(item){

      // 根据角色设置权限
      let userType=JSON.parse(localStorage.getItem('info')).userType
      if(userType == 1 || userType ==2) {
        this.queryEmployee(item.empId)
        this.getPageDepartment()
        this.dialogVisible = true
      }else {
         return this.$message({
          message: "暂无权限",
          type: "warning",
         });
      }
    },
    sectorSetup(){
      this.$router.push('/ent/sectorSetup')
    },
    AdministratorList(){
      this.$router.push('/ent/AdministratorList')
    },
    affirm_tap(){
      if(this.memberInfo.userType == 0){
       this.memberInfo.routers = ['00', '01','010', '02', '020']
      }else{
        // 设置默认权限
       let initRoute = filterUserRoot()
       let arr = this.setInitRoutes(initRoute,JSON.parse(localStorage.getItem('info')).userType)
       this.memberInfo.routers = arr
      }
      updateEmployee(this.memberInfo)
      .then(res=>{
        this.dialogVisible = false
        this.getCorporator()
      })
    },
    downCodeImg(data) {
      let params = {
        cardId: data.cardId
      }
      getWxCodeImg(params).then(res => {
        if(res.code === 200) {
          let url = res.data
          const a = document.createElement('a')
          a.href = url
          a.download = data.cardId
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
        }
      })
    },
    // 更换超管
    changeBoss() {
      this.changeDialogVisible = true
    },
    closeChangeDialog() {
      this.changeDialogVisible = false
      this.newUserId = ''
    },
    handleChangeBoss() {
      if(!this.newUserId) {
        this.$message({
          message: "请选择新超管",
          type: "error",
        });
        return
      }
      let params = {
        companyId: this.userInfo.companyId,
        userId: this.userInfo.userId,
        toUserId: this.newUserId,
      }
      let _this = this
      fetchChangeBoss(params).then(res => {
        if(res.code === 200) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          _this.$router.push("/login")
          utils.clearLoginInfo()
        }
        this.closeChangeDialog()
      })
    }
  },
};
</script>

<style scoped>
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
/deep/ input[type="number"] {
  -moz-appearance: textfield !important;
}
</style>
