<template>
    <el-dialog
      title=""
      width="900px"
      :modal-append-to-body="true"
      :append-to-body="true"
      :visible.sync="visible"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <commonTable
          :tableData="data"
          :total="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
        >
        <template v-slot:table>
          <el-table-column prop="userName" align="center" label="用户名">
          </el-table-column>
          <el-table-column prop="createTime" align="center" label="绑定时间">
          </el-table-column>
          <el-table-column align="center" label="操作"  width="120">
            <template slot-scope="scope">
              <el-button type="text" class="hover_pointer" @click="del(scope.row)">
                解绑
              </el-button>
            </template>
          </el-table-column>
        </template>

      </commonTable>
    </el-dialog>
</template>
<script>
import commonTable from "@/components/common/commonTable";
import {
  userClientBind,
  userClientUnbind
} from "@/api/companyManage";
import fa from "element-ui/src/locale/lang/fa";
export default {
    components:{ commonTable },
    data() {
        return {
            visible: false,
            pageNum: 1,
            pageSize: 10,
            total: 0,
            data: [],
            userId: 0
        }
    },
    methods:{
      del(item) {
        this.$confirm("确定要解绑当前用户吗？", "提示", {
          onConfirmButtonText: "确定",
          cancelButtonText: "取消",
          beforeClose: (action, instance, done) => {
            instance.modal = false;
            done();
          },
        }).then(() => {
          userClientUnbind({ userName: item.userName }).then((res) => {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.getList();
            });
          })
          .catch((e) => {
            console.log(e)
          });
      },
      openDialog(option){
        if(option.userId) {
          this.userId= option.userId
          this.getList()
        }
        this.visible = true
      },
      handleClose() {
       this.visible = false
      },
      handleSizeChange(num) {
        this.pageSize = num;
        this.pageNum = 1;
        this.getList();
      },
      handleCurrentChange(num) {
        this.pageNum = num;
        this.getList();
      },
      getList() {
          let params ={
            pageNum: this.pageNum,
            pageSize: this.pageSize,
            userId: this.userId
          }
          userClientBind(params).then(res => {
            this.data =  res.data.list
            this.total = res.data.total
          })
      }
    }
}
</script>
